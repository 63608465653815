/*
 * Welcome to your app's main JavaScript file!
 *
 * We recommend including the built version of this JavaScript file
 * (and its CSS file) in your base layout (base.html.twig).
 */

//require('bootstrap');



// You can specify which plugins you need
//import { Tooltip, Toast, Popover } from 'bootstrap';

// start the Stimulus application
//import './bootstrap';


console.log("launch 3.3");
// Need jQuery? Install it with "yarn add jquery", then uncomment to import it.
// import $ from 'jquery'; 
require("jquery-ui");
 
import $ from 'jquery';

import 'jquery-ui/ui/widget';
import 'jquery-ui/ui/widgets/draggable';
//    .addEntry('app', './assets/js/bootstrap-confirmation.js')
//import $ from 'jquery-ui/external/jquery/jquery.js';
//const $ = require('jquery');
global.$ = $;

console.log('JQuery is Loaded');
/*
 * autocomplete est dans jquery-ui, l'importer et effectuer import extarnals de jquery-ui pour jquery 3.6
 * 
 */
// You can specify which plugins you need
import { Tooltip, Toast, Popover } from 'bootstrap';

console.log("PopOver : " + Popover);
import autocomplete from 'jquery-ui/ui/widgets/autocomplete.js';
//import autocomplete from 'autocomplete.js';
//import 'autocomplete.js/dist/autocomplete.jquery';

// start the Stimulus application

// this "modifies" the jquery module: adding behavior to it
// the bootstrap module doesn't export/return anything
import './bootstrap.js';
require('bootstrap');

    console.log('Bootstrap JS is Loaded');
//require('bootstrap-confirmation2');
//require('modernizr');
// or you can include specific pieces
require('bootstrap/js/dist/tooltip');
require('bootstrap/js/dist/popover');


$(document).ready(function() {

    console.log("document reader jquery ready");
    $('[data-bs-toggle="popover"]').popover();
  $('[data-bs-toggle="tooltip"]').tooltip();
    
});


console.log('Hello Webpack Encore! Edit me in assets/js/app.js');

import './css/app.scss';